var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-root"
  }, [_c('div', {
    staticClass: "error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center",
    style: {
      backgroundImage: "url(".concat(_vm.backgroundImage, ")")
    }
  }, [_vm._m(0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container d-flex flex-row-fluid flex-column justify-content-md-center p-12"
  }, [_c('h1', {
    staticClass: "error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12"
  }, [_vm._v(" Oops! ")]), _c('p', {
    staticClass: "font-weight-boldest display-4"
  }, [_vm._v(" Something went wrong here. ")]), _c('p', {
    staticClass: "font-size-h3"
  }, [_vm._v(" We're working on it and we'll get it fixed "), _c('br'), _vm._v("as soon possible. "), _c('br'), _vm._v("You can back or use our Help Center. ")])]);

}]

export { render, staticRenderFns }